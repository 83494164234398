import React from "react";
import Helmet from "../components/Helmet";
import Jumbotron from "../components/Jumbotron";

function TermsOfService() {
    return (
        <React.Fragment>
            <Helmet title="Algemene voorwaarden"/>
            <Jumbotron>
                <h1>Algemene voorwaarden</h1>
            </Jumbotron>
            <div className="container">
                <h2>
                    Artikel 1 – Definities en toepasselijkheid
                </h2>
                <p>
                    In deze algemene voorwaarden wordt verstaan onder:
                </p>
                <ol>
                    <li>
                        Opdrachtnemer: Business Coach Maaike van Kuik, ingeschreven in het
                        handelsregister onder nummer: 62779168 tnv Mashtag, die deze algemene
                        voorwaarden gebruikt voor het aanbieden van producten/ diensten.
                    </li>
                    <li>
                        Opdrachtgever: de persoon of instantie, die aan de opdrachtnemer
                        opdracht verleent tot het verrichten van diensten.
                    </li>
                    <li>
                        Cliënt: degene die gebruik maakt van een van de diensten die de
                        opdrachtnemer aanbiedt.
                    </li>
                    <li>
                        De opdrachtgever en de cliënt kunnen, maar behoeven niet, dezelfde zijn.
                    </li>
                    <li>
                        Tenzij schriftelijk anders overeengekomen, zijn deze algemene
                        voorwaarden van toepassing op alle aanbiedingen en overeenkomsten van
                        Mashtag / Maaike van Kuik.
                    </li>
                    <li>
                        Eventuele afwijkingen op deze algemene voorwaarden zijn slechts geldig
                        indien deze uitdrukkelijk en schriftelijk zijn overeengekomen.
                    </li>
                    <li>
                        De toepasselijkheid van eventuele voorwaarden van de opdrachtgever
                        wordt door de opdrachtnemer uitdrukkelijk van de hand gewezen, tenzij
                        deze door de opdrachtnemer uitdrukkelijk schriftelijk zijn aanvaard.
                    </li>
                    <li>
                        Aan het stilzwijgend niet toepassen van deze algemene voorwaarden
                        kunnen geen rechten worden ontleend.
                    </li>
                </ol>

                <h2>
                    Artikel 2 - Totstandkoming en nakoming overeenkomst
                </h2>
                <ol>
                    <li>
                        Een overeenkomst tussen en door de opdrachtnemer en een
                        opdrachtgever komt tot stand door aanbod en aanvaarding.
                    </li>
                    <li>
                        Een opdracht wordt verleend door het betalen van de door de
                        opdrachtnemer uitgebrachte prijs, dan wel door akkoord te geven op een
                        digitaal door opdrachtnemer gestuurde factuur.
                    </li>
                    <li>
                        De overeenkomst wordt tevens geacht tot stand te zijn gekomen
                        overeenkomstig de door de opdrachtnemer uitgebrachte factuur, zodra met de
                        feitelijke dienstverlening door de opdrachtnemer een aanvang is gemaakt.
                    </li>
                    <li>
                        De met de opdrachtnemer gesloten overeenkomst leidt voor de
                        opdrachtnemer tot een inspanningsverplichting, niet tot een
                        resultaatverplichting.
                    </li>
                    <li>
                        De opdrachtnemer is gehouden om de door haar te verrichten
                        werkzaamheden naar beste inzicht en vermogen uit te voeren,
                        overeenkomstig de eisen van goed vakmanschap.
                    </li>
                    <li>
                        Indien er sprake is van overmacht, waardoor nakoming van de
                        overeenkomst redelijkerwijs niet van de opdrachtnemer kan worden gevergd,
                        zal de uitvoering van de overeenkomst worden opgeschort, om op een later
                        tijdstip alsnog nagekomen te worden, dan wel de overeenkomst worden
                        beëindigd, alles zonder enige verplichting tot schadevergoeding.
                    </li>
                </ol>

                <h2>
                    Artikel 4 – Informatieverstrekking door opdrachtgever
                </h2>
                <ol>
                    <li>
                        De opdrachtgever is verplicht om alle persoonsgegevens aan te leveren
                        die door de opdrachtnemer worden gevraagd. De opdrachtnemer zal alleen
                        om gegevens vragen die nodig zijn voor het uitvoeren van de
                        overeenkomst. Indien zich na het tot stand komen van de overeenkomst
                        wijzigingen voordoen in de opgegeven gegevens van de opdrachtgever,
                        dan dient dit aan de opdrachtnemer doorgegeven te worden.
                    </li>
                    <li>
                        De opdrachtnemer is niet verantwoordelijk voor de gevolgen van het niet
                        hebben ontvangen van informatie door de opdrachtgever vanwege onjuiste
                        (adres)gegevens.
                    </li>
                </ol>

                <h2>
                    Artikel 5 – Betaling
                </h2>
                <ol>
                    <li>
                        Betaling dient te geschieden binnen de op de factuur aangegeven termijn,
                        op een door de opdrachtnemer aan te geven wijze, tenzij anders is
                        overeengekomen. Indien geen termijn is overeengekomen, dient betaling
                        te geschieden binnen 14 dagen na factuurdatum.
                    </li>
                    <li>
                        Indien de opdrachtgever in gebreke blijft met de tijdige betaling van de
                        factuur, dan is hij onmiddellijk en zonder ingebrekestelling in verzuim. In
                        dat geval kan de opdrachtnemer besluiten om de dienstverlening aan de
                        opdrachtgever op te schorten dan wel de overeenkomst met onmiddellijke
                        ingang te beëindigen. De opdrachtnemer zal de opdrachtgever van haar
                        eventuele voornemen tot opschorting van de dienstverlening dan wel
                        beëindiging van de overeenkomst, schriftelijk in kennis stellen.
                    </li>
                    <li>
                        Bezwaren tegen de hoogte van de factuur schorten de
                        betalingsverplichting van de opdrachtgever niet op.
                    </li>
                    <li>
                        Alle met de incasso gemoeid gaande (buiten)gerechtelijke kosten komen
                        voor rekening van de opdrachtgever.
                    </li>
                    <li>
                        Tenzij schriftelijk anders is overeengekomen, is de opdrachtgever degene
                        die gehouden is tot betaling van de overeengekomen prijs voor de
                        dienstverlening van de opdrachtnemer.
                    </li>
                </ol>

                <h2>
                    Artikel 6 – Annulering/beëindiging van de overeenkomst
                </h2>
                <ol>
                    <li>
                        Opdrachtnemer heeft het recht om zonder opgave van reden een
                        coachsessie, coachtraject, workshop of training te annuleren of deelname
                        van een opdrachtgever of cliënt te weigeren, dan wel de door de
                        opdrachtgever aangewezen coachee te weigeren, in welke gevallen de
                        opdrachtgever recht heeft op terugbetaling van het volledige door deze aan
                        opdrachtnemer betaalde bedrag.
                    </li>
                    <li>
                        Annulering door de opdrachtgever kan tot 6 weken voor aanvang van een
                        coachtraject, workshop of training kosteloos geschieden. Bij niet
                        annulering is de opdrachtgever verplicht het totaalbedrag van het
                        coachtraject, de coachsessie, workshop of training te voldoen.
                    </li>
                    <li>
                        Ingeval de opdrachtgever dan wel de cliënt, na aanvang van het
                        coachtraject of training de deelname tussentijds beëindigt of daaraan
                        anderszins niet deelneemt, heeft de opdrachtgever geen recht op enige
                        terugbetaling, tenzij de bijzondere omstandigheden van het geval, naar het
                        oordeel van opdrachtnemer, anders rechtvaardigen.
                    </li>
                    <li>
                        Een individuele coachsessie kan tot 24 uur voor aanvang kosteloos worden
                        afgezegd c.q. verplaatst. Opdrachtnemer streeft ernaar afspraken altijd
                        door te laten gaan c.q. te verplaatsen naar een ander tijdstip. Bij afzegging
                        binnen 24 uur is opdrachtnemer gerechtigd de eerder overeengekomen
                        prijs in rekening te brengen.
                    </li>
                    <li>
                        De opdrachtnemer heeft het recht de overeenkomst met onmiddellijke
                        ingang en zonder rechterlijke tussenkomst op te zeggen, indien de
                        opdrachtgever tekortschiet in de nakoming van de verplichtingen die
                        voortvloeien uit de overeenkomst, waaronder begrepen de in deze
                        voorwaarden vastgelegde verplichtingen, tenzij de opdrachtgever, na
                        daartoe schriftelijk te zijn aangemaand, zijn verplichtingen binnen 14 dagen
                        na verzending van deze aanmaning alsnog nakomt.
                    </li>
                    <li>
                        Het Coachingtraject dient binnen een jaar start aanvang opgemaakt te zijn.
                        Anders vervalt het traject.
                    </li>
                </ol>

                <h2>
                    Artikel 7 – Aansprakelijkheid en risico
                </h2>
                <ol>
                    <li>
                        De opdrachtnemer aanvaardt geen enkele aansprakelijkheid jegens de
                        opdrachtgever ontstaan als gevolg van een aan opdrachtnemer
                        toerekenbare tekortkoming of onrechtmatige daad.
                    </li>
                    <li>
                        Indien de opdrachtnemer aansprakelijk mocht zijn voor enigerlei schade,
                        dan is de aansprakelijkheid beperkt tot maximaal het bedrag van de totale
                        vergoeding van een verstrekte opdracht.
                    </li>
                    <li>
                        De in dit artikel opgenomen beperkingen van de aansprakelijkheid gelden
                        niet indien de schade te wijten is aan opzet of grove schuld van de
                        opdrachtnemer of haar ondergeschikten.
                    </li>
                    <li>
                        Indien door of in verband met het verrichten van diensten door de
                        opdrachtnemer of anderszins schade aan personen of zaken wordt
                        toegebracht, waarvoor de opdrachtnemer aansprakelijk is, zal die
                        aansprakelijkheid zijn beperkt tot het bedrag van de uitkering uit hoofde
                        van de door de opdrachtnemer afgesloten aansprakelijkheidsverzekering,
                        met inbegrip van het eigen risico dat de opdrachtnemer in verband met die
                        verzekering draagt.
                    </li>
                </ol>

                <h2>
                    Artikel 8: Overmacht
                </h2>
                <p>
                    De opdrachtnemer is niet gehouden tot nakoming van enige verplichting jegens de
                    opdrachtgever, indien zij daartoe gehinderd wordt door overmacht. De
                    opdrachtnemer kan gedurende de periode dat de overmacht duurt, de verplichtingen
                    uit de overeenkomst opschorten. Indien deze periode langer dan zes maanden
                    duurt, zijn beide partijen gerechtigd de overeenkomst te ontbinden, zonder
                    verplichting tot vergoeding van schade aan de andere partij. Werkzaamheden die
                    door de opdrachtnemer zijn verricht voor het intreden van de overmacht, mogen aan
                    de opdrachtgever gefactureerd worden.
                </p>

                <h2>
                    Artikel 9 – Intellectueel eigendom en gebruikersrecht
                </h2>
                <p>
                    Het auteursrecht en eventuele andere intellectuele eigendomsrechten blijven
                    berusten bij de opdrachtnemer. Alle door de opdrachtnemer geproduceerde stukken
                    en/of verstrekte stukken, zoals rapporten, adviezen, berekeningen enz., zijn
                    uitsluitend bestemd voor gebruik door de opdrachtgever en mogen niet zonder
                    voorafgaande toestemming van de opdrachtnemer worden verveelvoudigd,
                    openbaar gemaakt, of door anderen dan opdrachtnemer ten uitvoer worden
                    gebracht, tenzij uitdrukkelijk anders overeengekomen of tenzij uit de aard van de
                    verstrekte stukken anders voortvloeit.
                </p>

                <h2>
                    Artikel 10 - klachtenregeling
                </h2>
                <p>
                    Eventuele klachten over door de opdrachtnemer geleverde diensten dienen binnen 8
                    dagen na levering hiervan, schriftelijk en gemotiveerd aan de opdrachtnemer
                    kenbaar te worden gemaakt. Bij gebreke hiervan wordt de opdrachtgever geacht
                    akkoord te gaan met de geleverde dienstverlening. Het indienen van een klacht
                    ontslaat de opdrachtgever niet van zijn betalingsverplichting.
                </p>

                <h2>
                    Artikel 11 - slotbepaling
                </h2>
                <ol>
                    <li>
                        Indien een bepaling in deze algemene voorwaarden nietig is of mocht
                        worden vernietigd, blijven de overige bepalingen van deze algemene
                        voorwaarden volledig van toepassing. Ter vervanging van de nietige c.q.
                        vernietigde bepaling, zullen de opdrachtnemer en de opdrachtgever in
                        overleg een nieuwe bepaling overeenkomen. Hierbij zal zoveel mogelijk het
                        doel en de strekking van de oorspronkelijke bepaling in acht worden
                        genomen.
                    </li>
                    <li>
                        Op de overeenkomst tussen de opdrachtnemer en de opdrachtgever is het
                        Nederlands recht van toepassing.
                    </li>
                    <li>
                        Deze voorwaarden blijven van kracht indien de opdrachtnemer van naam,
                        rechtsvorm of eigenaar verandert.
                    </li>
                </ol>
            </div>
        </React.Fragment>
    );
}

export default React.memo(TermsOfService);
