import React from "react";

function Jumbotron({ children }) {
    return (
        <div className="jumbotron jumbotron-fluid mashtag-jumbotron">
            <div className="container">
                { children }
            </div>
        </div>
    );
}

export default React.memo(Jumbotron)
