import React from "react";
import {
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import {
    useShopCrateRoutes
} from "@shopcrate/shopcrate-framework";

import Navbar from "./components/navbar/Navbar";
import ScrollToTop from "./hooks/ScrollToTop";
import Footer from "./components/Footer";
import useTracking from "./hooks/useTracking";

import Layout from "./layout/Layout";
import CategoryLayout from "./layout/CategoryLayout";
import ProductLayout from "./layout/ProductLayout";

import Home from "./pages/Home";
import Page404 from "./pages/Page404";
import TermsOfService from "./pages/TermsOfService";
import PrivacyPolicy from "./pages/PrivacyPolicy";

const layouts = {
    regular: Layout,
    checkout: Layout,
    account: Layout,
    product: ProductLayout,
    category: CategoryLayout
}

function ShopRouter() {
    const shopCrateRoutes = useShopCrateRoutes(layouts);
    useTracking();
    return (
        <React.Fragment>
            <Navbar/>
            <ScrollToTop/>

            <Switch>
                <Route path="/" exact component={Home}/>
                <Route path="/algemene-voorwaarden" exact component={TermsOfService}/>
                <Route path="/privacyverklaring" exact component={PrivacyPolicy}/>

                { shopCrateRoutes }

                <Route path={["/index", "/index.html", "/index.htm", "/index.php"]}>
                    <Redirect to="/"/>
                </Route>
                <Route path="/" component={Page404}/>
            </Switch>

            <Footer/>
        </React.Fragment>
    )
}

export default ShopRouter;
