import React from "react";

import Helmet from "../components/Helmet";
import Jumbotron from "../components/Jumbotron";

function Layout({ children, title }) {
    return (
        <React.Fragment>
            <Helmet title={ title }/>
            <Jumbotron>
                <h1>{ title }</h1>
            </Jumbotron>
            <div className="container">
                { children }
            </div>
        </React.Fragment>
    );
}

export default React.memo(Layout);
