import React from "react";
import {
    Link
} from "react-router-dom";
import {
    Col,
    Container,
    Jumbotron,
    Row
} from "react-bootstrap";

function FooterSocialIcons() {
    return (
        <ul className="nav justify-content-center">
            <li className="nav-item">
                <a className="nav-link active text-white" href="https://facebook.com/Mashtagstudio" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-facebook"/>
                </a>
            </li>
            <li className="nav-item">
                <a className="nav-link active text-white" href="https://instagram.com/mashtag_agency" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-instagram"/>
                </a>
            </li>
            <li className="nav-item">
                <a className="nav-link active text-white" href="https://linkedin.com/in/maaike-van-kuik-64b05711a" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-linkedin"/>
                </a>
            </li>
        </ul>
    );
}

function Footer() {
    const firstYear = 2024;
    const currentYear = new Date().getFullYear();
    let yearString =  firstYear + " - " + currentYear;
    if(firstYear === currentYear) {
        yearString = String(currentYear);
    }

    return (
        <React.Fragment>
            <div className="flex-grow-1"/>
            <Jumbotron fluid className="footer mb-0 py-5">
                <Container>
                    <Row>
                        <Col md="4" className="my-0">
                            <h3 className="footer-title">Webshop</h3>
                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <Link to="/privacyverklaring" className="nav-link active footer-link">Privacyverklaring</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/algemene-voorwaarden" className="nav-link active footer-link">Algemene voorwaarden</Link>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
            <Jumbotron fluid className="footer-secondary mb-0 py-4">
                <Container className="text-white">
                    <div className="d-flex flex-column flex-lg-row align-items-md-center">
                        <div className="text-center d-lg-none flex-grow-1 mb-3">
                            <FooterSocialIcons/>
                        </div>
                        <div className="flex-lg-grow-1 text-center text-lg-left">
                            &copy; { yearString } Mashtag. Creatieve workshops en evenementen.
                            <br/>
                            <small>
                                A website by <a href="https://srproductions.nl" target="_blank" rel="noopener noreferrer" style={{ color: "white" }}>SR Productions</a>.
                                <span className="d-md-none"><br/></span>{" "}
                                Powered by <a href="https://shopcrate.nl" target="_blank" rel="noopener noreferrer" style={{ color: "white" }}>ShopCrate</a>.
                            </small>
                        </div>
                        <div className="d-none d-lg-block">
                            <FooterSocialIcons/>
                        </div>
                    </div>
                </Container>
            </Jumbotron>
        </React.Fragment>
    )
}

export default React.memo(Footer);
