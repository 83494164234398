import React from "react";

import Helmet from "../components/Helmet";
import Jumbotron from "../components/Jumbotron";

function Page404() {
    return (
        <React.Fragment>
            <Helmet title="404 Not Found"/>
            <Jumbotron>
                <h1>404 Not Found</h1>
            </Jumbotron>
        </React.Fragment>
    )
}

export default Page404;
