import React, {
    useContext
} from "react";
import {
    CategoryPageManager,
    CategoryPageProducts,
    CategoryPageContext
} from "@shopcrate/shopcrate-framework";
import ReactMarkdown from "react-markdown";
import Skeleton from "react-loading-skeleton";

import Page404 from "../pages/Page404";
import Helmet from "../components/Helmet";
import Jumbotron from "../components/Jumbotron";

function CategoryPageTitle() {
    const categoryContext = useContext(CategoryPageContext);
    return (
        <div>
            <Helmet title={ categoryContext.category?.name }/>
            { categoryContext.category ? (
                <React.Fragment>
                    <h1>{ categoryContext.category.name }</h1>
                    <ReactMarkdown>
                        { categoryContext.category.description }
                    </ReactMarkdown>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <h1><Skeleton width={ 300 }/></h1>
                    <Skeleton count={ 3 }/>
                </React.Fragment>
            )}
        </div>
    )
}

function CategoryLayout(props) {
    return (
        <CategoryPageManager
            error404Page={ <Page404/> }
            { ...props }
        >
            <Jumbotron>
                <CategoryPageTitle/>
            </Jumbotron>
            <div className="container">
                <CategoryPageProducts/>
            </div>
        </CategoryPageManager>
    )
}

export default React.memo(CategoryLayout);
