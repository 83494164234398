import React from "react";
import {
    Trans
} from "react-i18next";
import {
    ProductPageManager,
    ProductImageCarousel,
    ProductPagePriceDisplay,
    ProductPageStock,
    ProductLimitPerOrderNotice,
    ProductPagePreorderNotice,
    ProductAvailabilityCheckmark,
    ProductPageAddToCardButton,
    ProductEndDate,
    ProductEndDateCountdown,
    ProductPageDescription,
    ProductPageTitle,
    ProductPageStickyBottomBar,
    ProductPageContext
} from "@shopcrate/shopcrate-framework";

import Helmet from "../components/Helmet";
import Jumbotron from "../components/Jumbotron";

function ProductLayout({ ...props }) {
    return (
        <ProductPageManager { ...props }>
            <ProductPageContext.Consumer>
                { productContext => (
                    <Helmet title={ productContext.product?.name }/>
                ) }
            </ProductPageContext.Consumer>

            <Jumbotron>
                <ProductPageTitle showSubtitle={ !!props.showSubtitle ? props.showSubtitle : true }/>
            </Jumbotron>
            <div className="container mb-5">

                <div className="row">
                    <div className="col-md-8 mb-4">
                        <ProductImageCarousel/>
                    </div>
                    <div className="col-md-4">
                        <ProductPagePriceDisplay/>
                        <p className="text-muted" style={{ fontSize: "0.8rem" }}>
                            <b><ProductPageStock/></b>
                        </p>
                        <p className="text-muted" style={{ fontSize: "0.8rem" }}>
                            <b><ProductLimitPerOrderNotice/></b>
                        </p>
                        <h5 className="mt-2">
                            <ProductPagePreorderNotice/>
                        </h5>
                        <ProductAvailabilityCheckmark/>
                        <ProductPageAddToCardButton/>
                        <div className="mt-3">
                            <ProductEndDate/>
                            <div className="text-danger mt-1 text-monospace" style={{ fontSize: "1.2rem" }}>
                                <ProductEndDateCountdown/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-8">
                        <h3 className="mt-2">
                            <Trans i18nKey="description"/>
                        </h3>
                        <ProductPageDescription/>

                        {/*<h3 className="mt-5">Specificaties</h3>*/}
                        {/*<ProductPageSpecifications/>*/}
                    </div>
                </div>

                {/*<h3 className="mt-5">Gerelateerde producten</h3>*/}
                {/*<ProductPageRelatedProducts/>*/}
            </div>
            <div className="d-md-none">
                <ProductPageStickyBottomBar>
                    <ProductPageAddToCardButton/>
                </ProductPageStickyBottomBar>
            </div>
        </ProductPageManager>
    );
}

export default React.memo(ProductLayout);
