import React from "react";
import {
    Redirect
} from "react-router-dom";

import Helmet from "../components/Helmet";
import Jumbotron from "../components/Jumbotron";
import {
    development
} from "../index";

function Home() {
    return (
        <React.Fragment>
            <Helmet/>
            <Jumbotron>
                <h1>Mashtag Shop</h1>
            </Jumbotron>
            <Redirect to={ `/category/${development ? 22 : 23}` } />
        </React.Fragment>
    );
}

export default React.memo(Home);
