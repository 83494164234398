import React from "react";
import Helmet from "../components/Helmet";
import Jumbotron from "../components/Jumbotron";

function PrivacyPolicy() {
    return (
        <React.Fragment>
            <Helmet title="Privacyverklaring"/>
            <Jumbotron>
                <h1>Privacyverklaring</h1>
            </Jumbotron>
            <div className="container">
                <p>
                    Om de opdracht zo goed mogelijk uit te kunnen voeren, houdt de opdrachtnemer
                    een registratie bij van persoonlijke en administratieve gegevens. Om opdrachtgevers
                    en cliënten te garanderen dat hun privacy wordt beschermd en er zorgvuldig met
                    hun gegevens wordt omgegaan, hanteert de opdrachtnemer een privacyreglement.
                </p>

                <h2>
                    Artikel 1 – Persoonsgegevens
                </h2>
                <ol>
                    <li>
                        De door de opdrachtgever aan de opdrachtnemer verstrekte
                        persoonsgegevens, zijn persoonsgegevens in de zin van de Wet
                        Bescherming Persoonsgegevens (Wbp). De opdrachtnemer is er
                        verantwoordelijk voor dat deze persoonsgegevens in overeenstemming
                        met de Wbp en op behoorlijke en zorgvuldige wijze worden verwerkt.
                    </li>
                    <li>
                        De door de opdrachtgever verstrekte persoonsgegevens zijn uitsluitend
                        voor het doel bestemd waarvoor ze zijn overgedragen en afgegeven. Het
                        gaat hierbij om gegevens waarmee de opdrachtnemer de opdracht kan
                        uitvoeren, gegevens waarmee opdrachtnemer de financiële administratie
                        kan voeren en gegevens waarmee de opdrachtnemer contact kan leggen
                        met de opdrachtgever.
                    </li>
                    <li>
                        De opdrachtgever kan verzoeken om inzage van zijn persoonsgegevens
                        en om verbetering, aanvulling of wijziging van deze gegevens.
                    </li>
                </ol>

                <h2>
                    Artikel 2 – Geheimhouding
                </h2>
                <ol>
                    <li>
                        De opdrachtnemer behandelt alle informatie over individuele cliënten
                        vertrouwelijk en draagt er zorg voor dat deze informatie niet aan derden
                        bekend wordt. Uitsluitend in noodgevallen waarbij het leven van de cliënt
                        zelf of van anderen in gevaar is of dreigt te raken, kan hiervan worden
                        afgeweken.
                    </li>
                    <li>
                        De opdrachtnemer draagt er zorg voor dat deze
                        geheimhoudingsverplichting ook wordt nageleefd door eventueel bij de
                        uitvoering van haar werkzaamheden ingeschakelde derden.
                    </li>
                </ol>

                <h2>
                    Artikel 3 – Bewaartermijn
                </h2>
                <p>
                    De persoonsgegevens worden niet langer bewaard dan noodzakelijk is ten behoeve
                    van het uitvoeren van de opdracht, tenzij anders met de cliënt is afgesproken.
                </p>
            </div>
        </React.Fragment>
    );
}

export default React.memo(PrivacyPolicy);
