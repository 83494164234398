import React from "react";
import {
    Link,
    useRouteMatch
} from "react-router-dom";

function NavbarLink({ to, title, exact = false, hidden = false, onClick, extraLinks = [], style, className }) {
    const match = useRouteMatch({
        path: [to, ...extraLinks],
        exact: exact
    });
    const classNameInternal = "nav-item" + (match ? " active" : "") + (hidden ? " d-none" : "") + (className ? ` ${className}` : "");
    return (
        <li className={ classNameInternal } data-toggle="collapse" data-target=".navbar-collapse.show">
            <Link to={ to } className="nav-link" onClick={ onClick } style={ style }>
                { title }
                <i className="fa fa-angle-right d-lg-none ml-2"/>
            </Link>
        </li>
    );
}

export default React.memo(NavbarLink);
