import React from "react";

function NavbarExternalLink({ to, title, onClick, style }) {
    return (
        <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
            <a href={ to } className="nav-link" onClick={ onClick } style={ style }>
                { title }
                <i className="fa fa-angle-right d-lg-none ml-2"/>
            </a>
        </li>
    );
}

export default React.memo(NavbarExternalLink);
