import React, {
    useCallback,
    useContext,
    useMemo,
    useState
} from "react";
import {
    Container,
    Nav,
    Navbar as RBNavbar
} from "react-bootstrap";
import {
    AuthenticationContext,
    CartProductCountBadge,
    ShopContext
} from "@shopcrate/shopcrate-framework";

import NavbarLink from "./NavbarLink";
import NavbarExternalLink from "./NavbarExternalLink";
import useWindowSize from "../../hooks/WindowSize";

import logo from "../../img/mashtag-logo.svg";
import logoSmall from "../../img/mashtag-logo-small.svg";

function Navbar() {
    const authentication = useContext(AuthenticationContext);
    const shop = useContext(ShopContext);
    const windowSize = useWindowSize();

    const [showCollapsed, setShowCollapsed] = useState(false);
    const collapse = useCallback(() => {
        setShowCollapsed(false);
    }, []);

    const selectedLogo = useMemo(() => {
        if(windowSize.width < 1190 && windowSize.width >= 992) {
            return logoSmall;
        }
        if(windowSize.width < 385) {
            return logoSmall;
        }
        return logo;
    }, [windowSize]);

    return (
        <React.Fragment>
            <RBNavbar bg="light" expand="lg" className="fixed-top" expanded={ showCollapsed }>
                <Container fluid>
                    <div className="navbar-brand-container">
                        <a className="navbar-brand" href="https://mashtag.nl">
                            <img
                                src={ selectedLogo }
                                alt="Mashtag Agency"
                            />
                        </a>
                        <button
                            type="button"
                            className={ "navbar-toggle " + (showCollapsed ? "" : "collapsed") }
                            aria-controls="basic-navbar-nav"
                            onClick={ () => setShowCollapsed(!showCollapsed) }
                        >
                            <span className="icon-bar"/>
                            <span className="icon-bar"/>
                            <span className="icon-bar"/>
                        </button>
                    </div>
                    <RBNavbar.Collapse id="basic-navbar-nav">
                        <Nav>
                            <NavbarExternalLink to="https://mashtag.nl" title="Mashtag" onClick={collapse}/>
                            <NavbarExternalLink to="https://mashtag.nl/m-agency-blog" title="M# ondernemers blog" onClick={ collapse }/>
                            <NavbarLink to="/" title="M# shop" onClick={ collapse } exact extraLinks={["/category/*", "/product/*"]}/>
                            <NavbarExternalLink to="https://mashtag.nl/kennismaken" title="Kennismaken" onClick={ collapse }/>
                            <NavbarExternalLink to="https://mashtag.nl/reviews" title="Reviews" onClick={ collapse }/>
                            <NavbarExternalLink to="https://mashtag.nl/contact" title="Contact" onClick={ collapse }/>

                            { shop.shop && shop.shop.orderingEnabled && (
                                <NavbarLink
                                    to="/cart"
                                    className="ml-3"
                                    title={
                                        <span style={{ whiteSpace: "nowrap" }}>
                                            <i className="fas fa-shopping-cart"/>
                                            <CartProductCountBadge className="ml-2"/>
                                        </span>
                                    }
                                    onClick={ collapse }
                                    style={{ fontSize: "15px" }}
                                />
                            )}

                            { authentication.supported && (
                                <NavbarLink
                                    to="/account"
                                    title={ <i className="fas fa-user fa-fw"/> }
                                    onClick={ collapse }
                                    className="mr-3"
                                    style={{ fontSize: "15px" }}
                                />
                            )}

                            <NavbarExternalLink
                                to="https://www.facebook.com/Mashtagstudio"
                                title={ <i className="fab fa-facebook-square fa-fw"/> }
                                onClick={ collapse }
                                style={{ fontSize: "17px" }}
                            />
                            <NavbarExternalLink
                                to="https://www.instagram.com/mashtag_agency/"
                                title={ <i className="fab fa-instagram fa-fw"/> }
                                onClick={ collapse }
                                style={{ fontSize: "17px" }}
                            />
                            <NavbarExternalLink
                                to="https://www.linkedin.com/in/maaike-van-kuik-64b05711a/"
                                title={ <i className="fab fa-linkedin fa-fw"/> }
                                onClick={ collapse }
                                style={{ fontSize: "17px" }}
                            />
                        </Nav>
                    </RBNavbar.Collapse>
                </Container>
            </RBNavbar>

            <div className="navbar-spacer"/>
        </React.Fragment>
    );
}

export default React.memo(Navbar);
